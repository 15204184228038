<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.考勤确认')"
                 fixed
                 @click-left="back">
    </van-nav-bar>
    <div style="padding-top:46px;">
      <div class="self_top">
        <div>
          <p>
            <span>{{nowDate}}</span>
            <span>{{dayArr[nowDay]}}</span>
          </p>
          <p>{{nowYear + '-' + nowMonth}}</p>
        </div>
      </div>
      <div class="self_center">
        <div>
          <p>{{ $t('tbm.上班') }}：{{todayTime.startTime}}</p>
          <p>{{ $t('tbm.签到') }}：{{todayTime.signStartTime}}</p>
        </div>
        <div>
          <p>{{ $t('tbm.下班') }}：{{todayTime.endTime}}</p>
          <p>{{ $t('tbm.签退') }}：{{todayTime.signEndTime}}</p>
        </div>
        <!-- <div>
          <span @click="weiClick">{{ $t('tbm.打卡详情') }}</span>
          <span @click="weiClick">{{ $t('tbm.异常处理') }}</span>
        </div> -->
      </div>
      <div class="self_bottom">
        <div>
          <div @click="showChooseDate=true">
            <van-icon name="notes-o" />
            <span style="margin-left:5px;">{{showDate}}</span>
            <van-icon name="play"
                      style="transform: rotate(90deg);" />
          </div>
          <p @click="goRouter">{{ $t('tbm.考勤详情') }}</p>
        </div>
        <div>
          <div v-for="(item,index) in tableThead"
               :key="index"
               v-if="data[item.headKey]>0">
            <label>{{item.headName}}：</label>
            <p>{{data[item.headKey]}}天</p>
          </div>
        </div>
      </div>
      <div class="self_sure"
           id="showType">
        <p v-if="(data.empFlag === '0' || data.empFlag === null) && data.managerFlag !== '1'"
           @click="confirmRow(data)">{{ $t('tbm.确认') }}</p>
        <p v-else-if="data.managerFlag === '1' "
           style="background-color:rgba(67, 120, 190, 0.53);">{{ $t('tbm.已封存') }}</p>
        <p v-else
           @click="unsealingRow(data)">{{ $t('tbm.解封') }}</p>
      </div>
      <van-popup v-model="showChooseDate"
                 v-if="showChooseDate"
                 position="bottom"
                 round
                 :style="{ height: '30%' }">
        <van-datetime-picker v-model="chooseDate"
                             type="year-month"
                             @confirm="confirm"
                             :min-date="minDate"
                             :max-date="maxDate"
                             @cancel="showChooseDate=false" />
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      minDate: new Date(),
      maxDate: new Date(),
      nowYear: new Date().getFullYear(),
      nowMonth: new Date().getMonth() + 1,
      nowDate: new Date().getDate(),
      nowDay: new Date().getDay(),
      dayArr: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      todayTime: {
        signStartTime: '未签',
        signEndTime: '未签',
        startTime: '***',
        endTime: '***'
      },
      searchData: {
        year: new Date().getFullYear(),
        pkPeriod: '',
        pkUser: '',
        startDate: '',
        endDate: ''
      },
      attendaceCycleDicList : [],
      tableThead: [
        {
          headName: this.$t('tbm.应出勤'),
          headKey: 'planNum',
        },
        {
          headName: this.$t('tbm.实出勤'),
          headKey: 'realNum',
        },
        {
          headName: '迟到(次)',
          headKey: 'lateNum',
        },
        {
          headName: '早退(次)',
          headKey: 'earlyNum',
        },
        {
          headName: this.$t('tbm.旷工'),
          headKey: 'absenceNum',
        },
        {
          headName: this.$t('tbm.公出'),
          headKey: 'outNum',
        },
        {
          headName: this.$t('tbm.加班'),
          headKey: 'totalOverNum',
        },
        {
          headName: this.$t('tbm.其他'),
          headKey: 'othereLeave',
        },
        {
          headName: this.$t('tbm.事假'),
          headKey: 'compassionateLeave',
        },
        {
          headName: this.$t('tbm.病假'),
          headKey: 'sickLeave',
        },
        {
          headName: this.$t('tbm.年休假'),
          headKey: 'annualLeave',
        },
        {
          headName: this.$t('tbm.调休假'),
          headKey: 'vacationLeave',
        },
        {
          headName: this.$t('tbm.公假'),
          headKey: 'publicLeave',
        },
        {
          headName: this.$t('tbm.探亲假'),
          headKey: 'familyLeave',
        },
        {
          headName: this.$t('tbm.婚假'),
          headKey: 'marriageLeave',
        },
        {
          headName: this.$t('tbm.婚育假'),
          headKey: 'maternityLeave',
        },
        {
          headName: this.$t('tbm.丧假'),
          headKey: 'funeralLeave',
        },
        {
          headName: this.$t('tbm.工伤假'),
          headKey: 'workinjuriesLeave',
        },
        {
          headName: this.$t('tbm.陪产假'),
          headKey: 'paternityLeave',
        },
        {
          headName: this.$t('tbm.陪护假'),
          headKey: 'chaperoneLeave',
        },
      ],
      data: {},
      showDate: '',
      showChooseDate: false,
      chooseDate: '',
    }
  },
  methods: {
    goRouter () {
      this.$router.push({
        name: 'tbm/selfService/selfServiceDetail',
        query: {
          period: this.searchData.pkPeriod,
          showDate: this.showDate
        }
      })
    },
    weiClick () {
      this.$toast('服务即将开放，敬请期待...');
    },
    //考勤确认
    async confirmRow (row) {
      let result = await this.checkInApproval()
      if (result.data.message !== '') {
        this.$toast.fail(result.data.message);
        return false
      }
      row.pkPeriod = this.searchData.pkPeriod,
       // document.getElementById('showType').setAttribute('class', 'notclick')
     // $("#showType").addClass("notclick");//设为不可点击
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/summaryCycle/confirmSummaryCycle',
        data: {
          'entity': row
        }
      }).then(res => {
        // 成功回调方法
        this.$toast.success('考勤确认成功');
        this.search();
        // $("#showType").removeClass("notclick");//设为不可点击
       // document.getElementById('showType').setAttribute('class', '')
      });
    },
    //
    checkInApproval () {
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/summaryCycle/checkInApproval',
        params: {
          startDate: this.searchData.startDate,
          endDate: this.searchData.endDate
        },
      })
    },
    //解封
    unsealingRow (row) {
      if (row.managerFlag === '1') {
        this.$toast.fail('已经封存，不能解封');
        return false
      } else {
       // document.getElementById('showType').setAttribute('class', 'notclick')
       // $("#showType").addClass("notclick");//设为不可点击
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/summaryCycle/unsealingSummaryCycle',
          data: {
            'entity': row
          }
        }).then(res => {
          // this.$refs.myTableInfo.findAllProject(1);

          // 成功回调方法
          this.$toast.success('考勤解封成功');
          this.search();
          // $("#showType").removeClass("notclick");//设为不可点击
         // document.getElementById('showType').setAttribute('class', '')

        });
      }
    },
    confirm (value) {
      let year = new Date(value).getFullYear();
      let month = new Date(value).getMonth() + 1;
      this.chooseDate = new Date(year, month - 1);
      if (month < 10) {
        month = '0' + month;
      }

      this.showDate = year + '年' + month + '月';
      let checkDate = year + '-' + month + '-01'
      this.attendaceCycleDicList.map(item => {
        let value = item.value.split(',')[0]
        if (value == checkDate) {
          this.searchData.pkPeriod = item.ID;
        }
      })
      this.selectChanged(this.searchData.pkPeriod)
      this.search();
      this.showChooseDate = false;
    },
    back () {
      this.$router.push({
        name: 'Home'
      });
    },
    search () {
      var myentity = [];
      if (this.searchData.pkPeriod) {
        myentity.push({
          'field': 'pkPeriod',
          'opt': '=',
          'value': this.searchData.pkPeriod,
          'assemble': 'and'
        });
      }
      if (this.searchData.pkUser) {
        myentity.push({ 'field': 'pkUser', 'opt': '=', 'value': this.searchData.pkUser, 'assemble': 'and' });
      }
      if (JSON.stringify(myentity) === '[]') {
        return false;
      }
      let this_=this
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/summaryCycle/info',
        data: {
          'where': myentity,
          'pageNum': 0,
          'pageSize': 10,
        },
      }).then(res => {
        if (res.data.rows) {
          this.data = res.data.rows[0];
        } else {
          this.data = {}
        }
         let showDate = this_.attendaceCycleDicList.find(item =>
           item.ID == this_.searchData.pkPeriod).label.slice(0, 7).split('-');

        this.showDate = showDate[0] + '年' + showDate[1] + '月'
        this.chooseDate = new Date(showDate[0], showDate[1] - 1)
      })
    },
	 selectChanged (val){
      let periodDate = this.attendaceCycleDicList.find(item=>item.ID == val).label;
      if(periodDate !==undefined || periodDate !==null){
        let beginDate= periodDate.slice(0,10);
        let endDate =periodDate.slice(11,21);
        this.searchData.startDate=beginDate;
        this.searchData.endDate = endDate;
      }
    },
    getPeriodOption () {
      let this_=this
      var companyId = JSON.parse(localStorage.getItem('userInfo')).companyId;
      var userId = JSON.parse(localStorage.getItem('userInfo')).id;
      if (userId) {
        this.searchData.pkUser = userId;
      }
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/attendanceperiod/getPeriodOption',
        params: {
          'pkOrg': companyId,
          'year': new Date().getFullYear()
        }
      }).then(res => {
        var backData = res.data.ext;
        backData.forEach(res => {
          if (this.searchData.pkPeriod) {
            if (res.ID == this.searchData.pkPeriod) {
              let date = res.value.split(',');
              this.searchData.startDate = date[0];
              this.searchData.endDate = date[1];
            }
          } else if (res.value.substring(5, 7) === this_.nowMonth.toString()) {
            let date = res.value.split(',');
            this.searchData.startDate = date[0];
            this.searchData.endDate = date[1];
            this.searchData.pkPeriod = res.ID;
          }
        })
        this.attendaceCycleDicList = backData;
        this.queryNowAttenanceList();
        this.search();
      });
    },
    queryNowAttenanceList () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/summaryCycle/querySignInAndSignOutTime',
        params: {
          dateDetail: this.nowYear + '-' + this.nowMonth + '-' + this.nowDate,
        },
      }).then(res => {
        let { rows } = res.data;
        if (rows.length) {
          this.todayTime.startTime = rows[0].startTime || '***';
          this.todayTime.endTime = rows[0].endTime || '***';
          if (rows[0].sign_start_time) {
            this.todayTime.signStartTime = rows[0].sign_start_time.split(' ')[1].slice(0, 5);
          }
          if (rows[0].sign_end_time) {
            this.todayTime.signEndTime = rows[0].sign_end_time.split(' ')[1].slice(0, 5);
          }
        }
      });
    },
  },
  created () {
    this.minDate = new Date(this.nowYear, 0, 1);
    this.maxDate = new Date(this.nowYear + 4, 11, 31);
    if (this.nowMonth < 10) {
      this.nowMonth = '0' + this.nowMonth
    }
    if (this.nowDate < 10) {
      this.nowDate = '0' + this.nowDate
    }
    this.searchData.pkPeriod = this.$route.params.period || '';
    this.getPeriodOption();
  },
}
</script>
<style>
.self_top {
  width: 100%;
  height: 200px;
  background-image: url('../../../images/bgc/kaoqinSure.jpg');
  background-size: 100% 100%;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.self_top > div {
  width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  background: #fff;
  margin-top: -40px;
  color: rgb(67, 120, 190);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 10px #ebedf0;
}
.self_top > div > p:nth-child(1) span:nth-child(1) {
  font-weight: 600;
  font-size: 26px;
}
.self_top > div > p:nth-child(1) span:nth-child(2),
.self_top > div > p:nth-child(2) {
  font-size: 14px;
}
.self_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  margin: -50px 10px 10px;
  box-shadow: 0px 2px 10px rgba(0, 38, 76, 0.168627450980392);
}
.self_center > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.self_center > div span {
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 20px;
}
.self_center > div:nth-child(3) span:nth-child(1) {
  color: rgb(67, 120, 190);
  border: 1px solid rgb(67, 120, 190);
}
.self_center > div:nth-child(3) span:nth-child(2) {
  color: #fff;
  background-color: rgba(234, 105, 105, 1);
}
.self_center > div:nth-child(1) p:nth-child(2),
.self_center > div:nth-child(2) p:nth-child(2) {
  color: rgb(67, 120, 190);
}
.self_center > div:nth-child(1) p:nth-child(2),
.self_center > div:nth-child(2) p:nth-child(2),
.self_center > div:nth-child(3) span:nth-child(2) {
  margin-top: 15px;
}
.self_bottom {
  box-shadow: 0px 2px 10px rgba(0, 38, 76, 0.168627450980392);
  margin: 0 10px;
  background: #fff;
  border-radius: 8px;
}
.self_bottom > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}
.self_bottom > div:nth-child(1) > div {
  color: rgb(102, 102, 102);
  display: flex;
  align-items: center;
}
.self_bottom > div:nth-child(1) > p {
  color: rgb(67, 120, 190);
  padding: 3px 10px;
  border: 1px solid rgb(67, 120, 190);
  border-radius: 20px;
  font-size: 14px;
}
.self_bottom > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 70px;
}
.self_bottom > div:nth-child(2) > div {
  width: 45%;
  display: flex;
  align-items: center;
  height: 40px;
}
.self_sure {
  position: fixed;
  bottom: 0;
  height: 70px;
  background: #fff;
  width: 100%;
}
.self_sure p {
  width: 90%;
  height: 50px;
  border-radius: 10px;
  margin: 10px 20px;
  background-color: rgb(67, 120, 190);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
